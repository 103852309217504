.homeScreen {
    background-color: #000;
    min-height: 100vh;
  
}
.body {
    background-color: #000;
    min-height: 100vh;
}

.loading {
    color: white;
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  font-size: 5rem;
  /* animation: animate 1s linear forwards; */
  animation: blink 1.8s infinite;
}

@keyframes type {
    0% {
      opacity: 1;
    }
    100% {
      width: 30em;
      opacity: 1;
    }
  }

@keyframes blink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }