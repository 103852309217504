.row {
    color: white;
    margin-left: 20px;
}

.row__posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row__posters::-webkit-scrollbar {
    display: none;
}

.row__poster {
    max-height: 250px;
    object-fit: contain;
    margin-right: 10px;
    /* width: 100%; */
    transition: transform 450ms;

}

.row__poster:hover {
    transform: scale(1.08);
    opacity: 1;
    cursor: pointer;
}

.row__posterLarge {
    max-height: 350px;
    object-fit: fill;
}

.row__posterLarge:hover {
    transform: scale(1.09);
    opacity: 1;
    cursor: pointer;
}

.overlay {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}