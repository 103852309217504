.loginScreen {
    position: relative;
    height: 100%;
    /* background: url('https://thefatork.store/cdn/shop/articles/netflix.jpg?v=1669877376') center no-repeat; */
    background: url('../login_background.png') center ;
    background-size: cover;
}

.loginScreen__logo {
    position: fixed;
    left: 0;
    width: 12rem;
    object-fit: contain;
    padding-left: 20px;
    top: 20px;
}

.loginScreen_button {
    position: fixed;
    right: 20px;
    top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.loginScreen__gradient {
    width: 100%;
    z-index: 1;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0,0,0,0) 60%,
        rgba(0,0,0, 0.8) 100%
    );
}



.loginScreen__body {
    position: absolute;
    top: 30%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
    color: #fff;
    padding: 20px;
    width: 100%;
}

.loginScreen__body > h1 {
    font-size: 3.125rem;
    margin-bottom: 20px;
}

.loginScreen__body > h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 30px;
}

.loginScreen__body > h3 {
    font-size: 1.3rem;
    font-weight: 300;
}

.loginScreen__input {
    margin: 20px;
}

.loginScreen__getStarted {
    padding: 16px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    border: none;
    font-weight: 600;
    cursor: pointer;
}

.loginScreen__input > form > input {
    padding: 10px;
    outline-width: 0;
    height: 30px;
    width: 30%;
    border: none;
    max-width: 600px;
}