.nav {
  position: fixed;
  top: 0;
  padding: 20px;
  z-index: 1;
  transition-timing-function: ease-in;
  transition: all, 0.5s;
  overflow: hidden;
}

.nav__contents {
  display: flex;
  justify-content: space-between;
}

.nav__black {
  background-color: #111;
}

.nav__logo {
  position: fixed;
  width: 10rem;
  object-fit: contain;
  left: 20px;
  cursor: pointer;
}

.nav__avatar {
  position: fixed;
  right: 20px;
  width: 30px;
  cursor: pointer;
}
