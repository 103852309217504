.profileScreen {
  background-color: black;
  height: 100vh;
}

.profileScreen__body {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8%;
  max-width: 800px;
}

.profileScreen__info {
  display: flex;
}

.profileScreen__details {
  color: #fff;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.profileScreen__details > h2 {
  background-color: gray;
  padding: 15px;
  font-size: 15px;
  padding-top: 20px;
}

.profileScreen__body > h1 {
  font-size: 60px;
  font-weight: 400;
  border-bottom: 1px solid #282c2d;
  margin-bottom: 20px;
}

.profileScreen__info > img {
  height: 100px;
}

.profileScreen__signOut {
  padding: 10px 20px;
  font-size: 1rem;
  margin-top: 5%;
  width: 100%;
  color: #fff;
  background-color: #e50914;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
.main_parent_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5%;
  margin-left: 30px;
}

.heading_text {
  font-size: 40px;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(173, 216, 230, 1) 50%,
    rgba(135, 206, 235, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 400;
  font-style: oblique;
}
.from_container {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 50px;
}
.img_container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}
.img {
  height: 50px;
  width: 60px !important;
}
.form_inner_container {
  min-width: 320px;
}
.text {
  color: white;
  margin-bottom: "5px";
  font-weight: 400;
  font-size: 20px;
  font-style: oblique;
  margin-top: 10px;
}
.border {
  margin-top: 25px;
  margin-bottom: 25px;
  border: solid;
  border-color: #766e6e;
  border-width: 1px;
  width: 90%;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_style {
  width: 220px;
  height: 40px;
  background-color: red;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  font-style: oblique;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .main_parent_div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
    flex-direction: column;
    margin-left: 30px;
  }

  .heading_text {
    font-size: 40px;
    color: transparent;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(173, 216, 230, 1) 50%,
      rgba(135, 206, 235, 1) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 400;
    font-style: oblique;
  }
  .from_container {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-left: 50px;
  }
  .img_container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
  }
  .img {
    width: 70px;
    height: 70px;
  }
  .form_inner_container {
    min-width: 320px;
  }
  .text {
    color: white;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
    font-style: oblique;
    margin-top: 10px;
  }
  .border {
    margin-top: 15px;
    margin-bottom: 5px;
    border: solid;
    border-color: #766e6e;
    border-width: 1px;
    width: 80%;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
  }
  .button_style {
    width: 120px;
    height: 40px;
    background-color: red;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    font-style: oblique;
    margin-top: 20px;
  }
}
