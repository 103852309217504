.videoscreen_container {
    width: 100%;
    height: 100vh;
    position: relative;
    
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100%;
    width: 100%; */
    opacity: 0;
    transition: .3s ease;
    /* cursor: pointer; */
    /* background-color: red; */
  }

  /* .videoscreen_container:hover .overlay {
    opacity: 1;
  } */
  
  .icon {
    color: white;
    font-size: 100px;
    position: absolute;
    padding-left: 10rem;
    padding-top: 8rem;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img {
      width: 50%;
  }
